import React, { FC, useEffect, useState } from 'react';
import classNames from 'classnames';
import StripeCardElement from 'components/CreditCard/StripeCardElement';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { currentOrderSaveCard } from 'state/atoms';

import { Field, Label, Switch } from '@headlessui/react';

interface ICreditCardComponent {
  onCompleted: () => void;
}

const CreditCardComponent: FC<ICreditCardComponent> = ({ onCompleted }) => {
  const [validCardnumber, setValidCardnumber] = useState<boolean>(false);
  const [validExpiry, setValidExpiry] = useState<boolean>(false);
  const [validCvc, setValidCvc] = useState<boolean>(false);

  const [enableSaveCard, setEnableSaveCard] = useState<boolean>(false);

  const setSaveCard = useSetRecoilState(currentOrderSaveCard);
  const saveCard = useRecoilValue(currentOrderSaveCard);

  useEffect(() => {
    if (validCardnumber && validExpiry && validCvc) {
      onCompleted();
      setEnableSaveCard(true);
    }
  }, [onCompleted, validCardnumber, validExpiry, validCvc]);

  return (
    <>
      <div className='mt-2'>
        <StripeCardElement
          label='Card Number'
          type='card-number'
          onChange={(e) => setValidCardnumber(e.complete)}
        />
        <div className='flex gap-4 mt-2'>
          <StripeCardElement
            label='Valid Thru'
            type='card-expiry'
            onChange={(e) => setValidExpiry(e.complete)}
          />
          <StripeCardElement
            label='CVC'
            type='card-cvc'
            onChange={(e) => setValidCvc(e.complete)}
          />
        </div>
      </div>
      <Field className='flex items-center mt-4'>
        <Switch
          checked={saveCard}
          onChange={setSaveCard}
          className={classNames(
            'group relative inline-flex h-6 w-11 flex-shrink-0 rounded-full border-2 border-transparent bg-gray-200 transition-colors duration-200 ease-in-out focus:outline-none data-[checked]:bg-green-950',
            {
              'cursor-not-allowed': !enableSaveCard,
            },
            {
              'cursor-pointer': enableSaveCard,
            },
          )}
          disabled={!enableSaveCard}
        >
          <span
            aria-hidden='true'
            className='pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out group-data-[checked]:translate-x-5'
          />
        </Switch>
        <Label
          as='span'
          className='ml-3 text-sm'
          onClick={() => {
            setSaveCard(!saveCard);
          }}
        >
          <span
            className={classNames(
              'font-medium',
              {
                'text-gray-300 cursor-not-allowed': !enableSaveCard,
              },
              {
                'text-gray-900 cursor-pointer': enableSaveCard,
              },
            )}
          >
            Save for future use
          </span>
        </Label>
      </Field>
    </>
  );
};

export default CreditCardComponent;
