import React, { FC } from 'react';
import amexLogo from 'assets/amex.svg';
import discoverLogo from 'assets/discover.svg';
import mastercardLogo from 'assets/mastercard.svg';
import visaLogo from 'assets/visa.svg';
import { UserCreditCard } from 'types/av-customer';

import {
  Label,
  Listbox,
  ListboxButton,
  ListboxOption,
  ListboxOptions,
} from '@headlessui/react';
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid';

interface ISavedCardsList {
  cards: UserCreditCard[];
  selectedCard: UserCreditCard | null;
  onSelect: (card: UserCreditCard) => void;
}

const creditCardLogos = {
  visa: visaLogo,
  mastercard: mastercardLogo,
  amex: amexLogo,
  discover: discoverLogo,
};

const CardListItem: FC<{ card: UserCreditCard | null }> = ({ card }) => {
  return (
    <div className='flex items-center'>
      <img
        className='mr-2 h-[20px] w-[32px]'
        src={creditCardLogos[card?.brand as keyof typeof creditCardLogos]}
        alt={card?.brand}
      />
      <span className='block truncate font-normal'>
        Ending in {card?.last4}
      </span>
    </div>
  );
};

const SavedCardsList: FC<ISavedCardsList> = ({
  cards,
  selectedCard,
  onSelect,
}) => {
  return (
    <Listbox
      value={selectedCard}
      onChange={(card) => {
        if (card) onSelect(card);
      }}
    >
      <Label className='block text-sm font-medium leading-6 text-gray-900'>
        Payment Method
      </Label>
      <div className='relative mt-2'>
        <ListboxButton className='relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-green-950 sm:text-sm sm:leading-6'>
          <span className='block truncate'>
            {selectedCard ? (
              <div className='flex items-center'>
                {selectedCard._id === 'new' ? (
                  <span className='block truncate font-normal group-data-[selected]:font-semibold'>
                    Enter manually
                  </span>
                ) : (
                  <CardListItem card={selectedCard} />
                )}
              </div>
            ) : (
              'Select a card'
            )}
          </span>
          <span className='pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2'>
            <ChevronUpDownIcon
              aria-hidden='true'
              className='h-5 w-5 text-gray-400'
            />
          </span>
        </ListboxButton>

        <ListboxOptions
          transition
          className='absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none data-[closed]:data-[leave]:opacity-0 data-[leave]:transition data-[leave]:duration-100 data-[leave]:ease-in sm:text-sm'
        >
          {cards?.map((cc) => (
            <ListboxOption
              key={cc._id}
              value={cc}
              className='group relative cursor-default select-none py-2 pl-3 pr-9 text-gray-900 data-[focus]:bg-green-950 data-[focus]:text-white'
            >
              {cc._id === 'new' ? (
                <span className='block truncate font-normal group-data-[selected]:font-semibold'>
                  Enter manually
                </span>
              ) : (
                <CardListItem card={cc} />
              )}
              <span className='absolute inset-y-0 right-0 flex items-center pr-4 text-green-950 group-data-[focus]:text-white [.group:not([data-selected])_&]:hidden'>
                <CheckIcon aria-hidden='true' className='h-5 w-5' />
              </span>
            </ListboxOption>
          ))}
        </ListboxOptions>
      </div>
    </Listbox>
  );
};

export default SavedCardsList;
