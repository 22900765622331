export enum API_ROUTES {
  AV_CUSTOMER = '/av-customer',
  GET_STATES = '/public/states',
  GET_TIMEZONE = '/public/timezone',
  VALIDATE_ZIP = '/public/validate-zip',
  VALIDATE_ADDRESS = '/public/validate-address',
  GET_TAX_RATE = '/av-vendor/av-order/get-tax-rate',
  AV_ORDER_INVOICE = '/generate-invoice-pdf',
  VENDOR = '/av-vendor',
  CREATE_SETUP_INTENT = '/av-vendor/av-order/create-setup-intent',
  CANCEL_SETUP_INTENT = '/av-vendor/av-order/cancel-setup-intent',
  UPDATE_SETUP_INTENT = '/av-vendor/av-order/update-setup-intent',
  UPDATE_ORDER_STATUS = '/update-order-status',
  CANCEL_ORDER = '/cancel-order',
  AUTOCOMPLETE_ADDRESS = '/public/autocomplete-address',
  PLACE_DETAILS = '/public/place-details',
  METADATA_KEYS_LABELS = '/metadata-keys-labels',
  USER_CREDIT_CARDS = '/user-credit-cards',
}

export enum PROTECTED_ROUTES {
  ORDRES = '/orders',
}

export enum PUBLIC_ROUTES {
  LOGIN = '/',
  RESET_PASSWORD = '/reset-password',
  FORGOT_PASSWORD = '/forgot-password',
  VERIFY_OTP = '/verify-otp',
}

export const AccountRequiredRoutes = [
  API_ROUTES.AV_CUSTOMER,
  API_ROUTES.VENDOR,
];
