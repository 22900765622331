import { ChangeEvent, FC, useCallback, useEffect, useState } from 'react';
import {
  ControllerRenderProps,
  FieldError,
  UseFormSetValue,
} from 'react-hook-form';
import AlertMessage from 'components/AlertMessage';
import Input from 'components/Inputs';
import { ORDER_ALERT_MESSAGES } from 'constants/orders';
import { API_ROUTES } from 'constants/routes';
import { useRequest } from 'hooks/useRequest';
import _ from 'lodash';
import { CreateAVOrder, State } from 'types/av-orders';
import { convertObjIntoQueryParam } from 'utils/helper';

interface PlacesAutocompleteProps {
  states: State[];
  field: ControllerRenderProps<CreateAVOrder, 'venue.address'>;
  error?: FieldError;
  setValue: UseFormSetValue<CreateAVOrder>;
  onEnterAddressManually?: () => void;
  onUseAutoComplete?: () => void;
}
interface ISuggestion {
  description: string;
  placeId: string;
  mainText: string;
}
interface IGoogleAddress {
  city: string;
  state: string;
  country: string;
  zipCode: string;
  fullAddress: string;
}

const PlacesAutocomplete: FC<PlacesAutocompleteProps> = ({
  error,
  field,
  states,
  setValue,
  onEnterAddressManually,
  onUseAutoComplete,
}) => {
  const [suggestions, setSuggestions] = useState<ISuggestion[]>([]);
  const [placeDetails, setPlaceDetails] = useState<IGoogleAddress | null>(null);
  const [showAlertMessage, setShowAlertMessage] = useState(false);
  const [enableAutoComplete, setEnableAutoComplete] = useState(true);

  const { get } = useRequest();

  const fetchPlaceDetails = async (placeId: string) => {
    try {
      const query = { placeId };
      const urlQuery = convertObjIntoQueryParam(query);
      const { data } = await get<IGoogleAddress>(
        `${API_ROUTES.PLACE_DETAILS}?${urlQuery}`,
      );
      setPlaceDetails(data ?? null);
    } catch (error) {
      console.error('Error fetching place details:', error);
    }
  };

  const fetchSuggestions = async (input: string) => {
    if (input.length < 3) {
      setSuggestions([]);
      return;
    }
    try {
      const query = { input };
      const urlQuery = convertObjIntoQueryParam(query);
      const response = await get<ISuggestion[]>(
        `${API_ROUTES.AUTOCOMPLETE_ADDRESS}?${urlQuery}`,
      );
      if (response?.data) {
        setSuggestions(response?.data);
      }
    } catch (error) {
      console.error('Error fetching suggestions:', error);
    }
  };

  const debouncedFetchSuggestions = _.debounce(fetchSuggestions, 500);

  const handleInputChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      field.onChange(e.target.value);
      if (!enableAutoComplete) {
        return;
      }
      debouncedFetchSuggestions(e.target.value);
    },
    [enableAutoComplete, field, debouncedFetchSuggestions],
  );

  const handleSuggestionClick = (suggestion: ISuggestion) => {
    fetchPlaceDetails(suggestion.placeId);
    setSuggestions([]);
    field.onChange(suggestion.mainText);
  };

  const setAddressDetails = useCallback(() => {
    if (!placeDetails) return;

    if (
      placeDetails?.state &&
      placeDetails?.zipCode &&
      placeDetails?.country === 'Puerto Rico'
    ) {
      const addressSuffix = `, ${placeDetails.state}, ${placeDetails.zipCode}, ${placeDetails.country}`;
      setValue(
        'venue.address',
        placeDetails?.fullAddress.replace(addressSuffix, ''),
        {
          shouldDirty: true,
        },
      );
    }

    if (placeDetails?.city && placeDetails.zipCode) {
      setValue('venue.city', placeDetails.city ?? '', { shouldDirty: true });
      setValue('venue.zip', placeDetails.zipCode ?? '', { shouldDirty: true });
      const state = states?.find((state) => state.name === placeDetails.state);
      setValue('venue.state', state?._id ?? '', { shouldDirty: true });
    } else {
      setValue('venue.address', '', { shouldDirty: true });
      setShowAlertMessage(true);
    }
  }, [placeDetails, states, setValue]);

  useEffect(() => {
    setAddressDetails();
  }, [setAddressDetails]);

  useEffect(() => {
    if (onEnterAddressManually && !enableAutoComplete) {
      onEnterAddressManually();
      setSuggestions([]);
    } else if (onUseAutoComplete && enableAutoComplete) {
      onUseAutoComplete();
    }
  }, [enableAutoComplete, onEnterAddressManually, onUseAutoComplete]);

  return (
    <div className='relative'>
      <AlertMessage
        title='Incomplete Address'
        message={ORDER_ALERT_MESSAGES.INCOMPLETE_ADDRESS}
        buttons={[
          {
            label: 'OK',
            className: '!text-white !bg-green-950 mr-2 !px-12 rounded-xl',
            onClick: () => {
              setShowAlertMessage(false);
            },
          },
        ]}
        isOpen={showAlertMessage}
        onClose={() => setShowAlertMessage(false)}
      />
      <Input
        type='text'
        label='Address'
        value={field.value}
        onChange={handleInputChange}
        error={error}
        name={field.name}
        placeholder='Enter Address'
        autoComplete='off'
      />

      <button
        type='button'
        onClick={(e) => {
          e.stopPropagation();
          setEnableAutoComplete(!enableAutoComplete);
        }}
        className='absolute top-[5px] right-[5px] text-xs text-gray-700 hover:text-green-950 visited:text-green-950'
      >
        {enableAutoComplete ? 'Enter Manually' : 'Use AutoComplete'}
      </button>

      {suggestions?.length ? (
        <div className='absolute w-full bg-white border border-gray-300 top-50 rounded-md z-10'>
          {suggestions?.map((suggestion) => (
            <div
              key={suggestion.placeId}
              className='text-gray-950 text-sm py-2 pl-8 pr-4 cursor-pointer m-0 hover:bg-stepperBg rounded-md'
              onClick={() => handleSuggestionClick(suggestion)}
            >
              {suggestion.description}
            </div>
          ))}
        </div>
      ) : null}
    </div>
  );
};

export default PlacesAutocomplete;
