import { useCallback, useEffect, useState } from 'react';
import { MdDeleteOutline } from 'react-icons/md';
import amexLogo from 'assets/amex.svg';
import discoverLogo from 'assets/discover.svg';
import mastercardLogo from 'assets/mastercard.svg';
import visaLogo from 'assets/visa.svg';
import AlertMessage from 'components/AlertMessage';
import Button from 'components/Buttons/Index';
import Loader from 'components/Loaders';
import Modal from 'components/Modal';
import { API_ROUTES } from 'constants/routes';
import { useRequest } from 'hooks/useRequest';
import { UserCreditCard } from 'types/av-customer';
interface ICreditCardsModal {
  onClose: () => void;
}

const creditCardLogos = {
  visa: visaLogo,
  mastercard: mastercardLogo,
  amex: amexLogo,
  discover: discoverLogo,
};

export const CreditCardsModal = ({ onClose }: ICreditCardsModal) => {
  const { get, delete: del, isLoading } = useRequest();
  const [userCreditCards, setUserCreditCards] = useState<UserCreditCard[]>();
  const [showErrorAlert, setShowErrorAlert] = useState<boolean>(false);
  const [selectedCard, setSelectedCard] = useState<string>('');

  const getUserSavedCards = useCallback(async () => {
    const { data } = await get<UserCreditCard[]>(
      `${API_ROUTES.AV_CUSTOMER}${API_ROUTES.USER_CREDIT_CARDS}`,
    );
    setUserCreditCards(data ?? []);
  }, [get]);

  const handleDeleteCard = useCallback(
    async (cardId: string) => {
      const { data: deleted } = await del<boolean>(
        `${API_ROUTES.AV_CUSTOMER}${API_ROUTES.USER_CREDIT_CARDS}/${cardId}/delete`,
      );
      if (deleted) {
        getUserSavedCards();
      }
    },
    [del, getUserSavedCards],
  );

  useEffect(() => {
    getUserSavedCards();
  }, [getUserSavedCards]);

  return (
    <Modal className='w-[30%] max-h-[80vh] !pt-3' open onClose={onClose}>
      <AlertMessage
        title='Delete Card'
        message={'Are you sure you want to delete this card?'}
        buttons={[
          {
            label: 'No',
            className:
              '!bg-white !border-gray-300 !text-gray-950 mr-2 !px-12 rounded-xl',
            onClick: () => setShowErrorAlert(false),
          },
          {
            label: 'Yes',
            className: '!text-white !bg-red-500 mr-2 !px-12 rounded-xl',
            onClick: () => {
              handleDeleteCard(selectedCard);
              setShowErrorAlert(false);
            },
          },
        ]}
        isOpen={showErrorAlert}
        onClose={() => setShowErrorAlert(false)}
      />
      <div className='flex justify-between items-center mb-3'>
        <p className='text-2xl font-normal text-gray-950'>Payment Methods</p>
      </div>
      <div className='flex gap-4 h-[calc(100%-80px)]'>
        {isLoading ? (
          <div className='w-full flex items-center justify-center'>
            <Loader />
          </div>
        ) : (
          <ul role='list' className='w-[100%] divide-y divide-gray-100'>
            {userCreditCards?.length !== 0 ? (
              userCreditCards?.map((cc) => (
                <li
                  key={cc._id}
                  className='flex items-center justify-between gap-x-6 py-5'
                >
                  <div className='min-w-0'>
                    <div className='flex items-center gap-x-2'>
                      <img
                        className='mr-2 h-[24px] w-[39px]'
                        src={
                          creditCardLogos[
                            cc.brand as keyof typeof creditCardLogos
                          ]
                        }
                        alt={cc.brand}
                      />
                      <p className='text-sm font-semibold leading-6 text-gray-600'>
                        Ending in {cc.last4}
                        <p className='text-xs text-gray-400'>
                          Expires {cc.expMonth}/{cc.expYear}
                        </p>
                      </p>
                    </div>
                  </div>
                  <div className='flex flex-none items-center gap-x-4'>
                    <a
                      className='hidden cursor-pointer px-2.5 py-1.5 text-md text-gray-600 sm:block'
                      onClick={() => {
                        setSelectedCard(cc._id);
                        setShowErrorAlert(true);
                      }}
                    >
                      <MdDeleteOutline size={20} />
                    </a>
                  </div>
                </li>
              ))
            ) : (
              <li className='flex items-center justify-center py-5'>
                <p className='text-sm font-semibold leading-6 text-gray-900'>
                  No credit cards saved
                </p>
              </li>
            )}
          </ul>
        )}
      </div>
      <div className='w-full flex items-center justify-end pt-3'>
        <Button
          className='!border-gray-300 !text-gray-500 mr-2 !px-12 rounded-xl focus-visible:outline-none'
          onClick={onClose}
          variant='outlined'
          label='Close'
          type='button'
        />
      </div>
    </Modal>
  );
};
