import React, { useCallback } from 'react';
import { MdCreditCard, MdLogout } from 'react-icons/md';
import { useLocation, useNavigate } from 'react-router-dom';
import { ReactComponent as Logo } from 'assets/the_IC_Logo 1.svg';
import { PROTECTED_ROUTES, PUBLIC_ROUTES } from 'constants/routes';
import { useSetRecoilState } from 'recoil';
import { authState } from 'state/atoms';
import { removeTrailingSlash } from 'utils/avUtils';

interface IHeader {
  onOpenCreditCardsModal?: () => void;
  showPaymentMethods?: boolean;
}

const Header = ({ onOpenCreditCardsModal, showPaymentMethods }: IHeader) => {
  const setAuth = useSetRecoilState(authState);
  const navigate = useNavigate();
  const location = useLocation();

  const isProtectedRoute = Object.values(
    PROTECTED_ROUTES as unknown as { [K: string]: string },
  ).includes(removeTrailingSlash(location.pathname));

  const onLogout = useCallback(() => {
    setAuth('');
    navigate(PUBLIC_ROUTES.LOGIN);
  }, [navigate, setAuth]);

  return (
    <div className='flex justify-between align-middle py-4 px-20 border-b'>
      <Logo className='w-36' />
      {isProtectedRoute ? (
        <div className='flex'>
          {showPaymentMethods && (
            <p
              className='flex items-center cursor-pointer gap-3 rounded-xl p-3 bg-slate text-normal font-normal text-gray-600 mr-4'
              onClick={onOpenCreditCardsModal}
            >
              <MdCreditCard size={20} />
              Payment Methods
            </p>
          )}
          <p
            className='flex items-center cursor-pointer gap-3 rounded-xl p-3 bg-slate text-normal font-normal text-gray-600'
            onClick={onLogout}
          >
            <MdLogout size={18} />
            Log out
          </p>
        </div>
      ) : null}
    </div>
  );
};

export default Header;
