/* eslint-disable max-lines */
import React, { useEffect, useMemo, useState } from 'react';
import {
  Control,
  Controller,
  UseFormSetValue,
  UseFormWatch,
} from 'react-hook-form';
import Datepicker, { DateType } from 'react-tailwindcss-datepicker';
import TimePicker from 'react-time-picker';
import { TimeZoneClasses } from 'constants/common';
import dayjs from 'dayjs';
import { AVOrder, CreateAVOrder, GoogleTimeZone } from 'types/av-orders';
import { AV_ORDER_STATUS } from 'utils/common.enum';

import { ErrorComponent, MainHeading } from './UtilComponents';

import '../../../TimePicker.css';
import 'react-clock/dist/Clock.css';

interface IOrderDateTimeForm {
  editOrder: AVOrder | undefined;
  control: Control<CreateAVOrder>;
  watch: UseFormWatch<CreateAVOrder>;
  setValue: UseFormSetValue<CreateAVOrder>;
  formValues: CreateAVOrder;
  googleTimezone: GoogleTimeZone | null;
  isWithinCancellationPolicy: boolean;
}

const OrderDateTimeForm: React.FC<IOrderDateTimeForm> = ({
  editOrder,
  control,
  googleTimezone,
  watch,
  setValue,
  formValues,
  isWithinCancellationPolicy,
}) => {
  const [tzCode, setTzCode] = useState('');

  const startDate = watch('startDate');
  const endDate = watch('endDate');

  useEffect(() => {
    if (googleTimezone) {
      const spittedNames = googleTimezone.name.split(' ');
      const splitedCodes = spittedNames.map((name) => name[0]);
      setTzCode(splitedCodes.join(''));
    }
  }, [googleTimezone]);

  useEffect(() => {
    if (formValues?.startDate && formValues?.startTime) {
      const startTime = dayjs(formValues.startDate)
        .set('hour', formValues.startTime.getHours())
        .set('minute', formValues.startTime.getMinutes());
      if (!formValues.setupTime) {
        const setupTime = startTime.subtract(1, 'hour').toDate();
        setValue('setupTime', setupTime, {
          shouldDirty: true,
          shouldValidate: true,
        });
      }

      const diff = startTime.diff(formValues?.startTime, 'days');
      if (diff !== 0) {
        const startTime = dayjs(formValues.startDate)
          .set('hour', formValues.startTime.getHours())
          .set('minute', formValues.startTime.getMinutes());
        setValue('startTime', startTime.toDate(), {
          shouldValidate: true,
        });
        if (formValues?.setupTime) {
          const setupTime = dayjs(formValues.startDate)
            .set('hour', formValues.setupTime.getHours())
            .set('minute', formValues.setupTime.getMinutes());
          setValue('setupTime', setupTime.toDate(), {
            shouldValidate: true,
          });
        }
      }

      if (!formValues.endTime) {
        const endTime = startTime.add(1, 'hour');
        setValue('endTime', endTime.toDate(), {
          shouldDirty: true,
          shouldValidate: true,
        });
        const startDate = dayjs(formValues.startDate);
        const endDate = dayjs(formValues.endDate);
        if (startDate.isSame(endDate, 'day') && endTime.isAfter(endDate)) {
          setValue('endDate', endTime.toDate(), {
            shouldValidate: true,
          });
        }
      }
    }
    if (formValues?.endDate && formValues?.endTime) {
      const endDate = dayjs(formValues.endDate)
        .set('hour', formValues.endTime.getHours())
        .set('minute', formValues.endTime.getMinutes());
      const diff = endDate.diff(formValues.endTime, 'days');
      if (diff !== 0) {
        const endTime = endDate
          .set('hour', formValues.endTime.getHours())
          .set('minute', formValues.endTime.getMinutes());
        setValue('endTime', endTime.toDate(), {
          shouldValidate: true,
        });
      }
    }
  }, [
    setValue,
    formValues.startDate,
    formValues.startTime,
    formValues.setupTime,
    formValues.endTime,
    formValues.endDate,
  ]);

  const disableDateTimeFields = useMemo(() => {
    return editOrder?.orderStatus === AV_ORDER_STATUS.ON_HOLD
      ? false
      : (!!editOrder && isWithinCancellationPolicy) ||
          (!!editOrder?.rushFee && editOrder?.rushFee > 0);
  }, [editOrder, isWithinCancellationPolicy]);

  return (
    <div>
      <MainHeading title='Date & Time' classNames='mt-8' />
      <div className='flex flex-col gap-x-1 items-start mt-1'>
        <div className='text-sm font-medium text-gray-900'>Local Timezone</div>
        <div className='text-sm text-gray-800 font-normal'>{`${
          googleTimezone?.id || ''
        } (${googleTimezone?.name || 'N/A'})`}</div>
      </div>
      <div className='flex justify-start items-center sm:flex-wrap xl:flex-nowrap gap-2 mt-2'>
        <div className='sm:w-2/5 xl:w-1/3 relative'>
          <label className='block text-sm font-medium leading-6 text-gray-900'>
            Local Start Date
          </label>
          <Controller
            control={control}
            name='startDate'
            rules={{
              required: 'Start Date is required',
            }}
            render={({ fieldState: { error }, field }) => (
              <>
                <Datepicker
                  inputName={field.name}
                  classNames={{
                    input: () =>
                      'border py-2 w-full px-3 rounded-md text-sm focus-visible:outline-green-950',
                  }}
                  asSingle
                  useRange={false}
                  onChange={(e) => {
                    field.onChange(e?.startDate);
                    if (!formValues.endDate && e?.startDate) {
                      setValue('endDate', e.startDate as Date, {
                        shouldDirty: true,
                        shouldValidate: true,
                      });
                    }
                  }}
                  minDate={new Date()}
                  value={{
                    startDate: field?.value as DateType,
                    endDate: field?.value as DateType,
                  }}
                  displayFormat={'MM/DD/YYYY'}
                  disabled={disableDateTimeFields}
                />
                <ErrorComponent id='startDate-error' msg={error?.message} />
              </>
            )}
          />
        </div>
        <div className='sm:w-2/5 xl:w-1/3 relative'>
          <label className='block text-sm font-medium leading-6 text-gray-900'>
            Local End Date
          </label>
          <Controller
            control={control}
            name='endDate'
            rules={{
              required: 'End Date is required',
            }}
            render={({ fieldState: { error }, field }) => (
              <>
                <Datepicker
                  classNames={{
                    input: () =>
                      'border py-2 w-full px-3 rounded-md text-sm focus-visible:outline-green-950',
                  }}
                  asSingle
                  useRange={false}
                  onChange={(e) => {
                    field.onChange(e?.startDate);
                  }}
                  minDate={formValues?.startDate}
                  value={{
                    startDate: field.value as DateType,
                    endDate: field.value as DateType,
                  }}
                  displayFormat={'MM/DD/YYYY'}
                  disabled={disableDateTimeFields}
                />
                <ErrorComponent id='startDate-error' msg={error?.message} />
              </>
            )}
          />
        </div>
      </div>

      <div className='flex justify-start items-center gap-2 mt-4'>
        <div className='sm:w-2/5 xl:w-1/3 relative'>
          <label className='block text-sm font-medium leading-6 text-gray-900'>
            Local Start Time
          </label>
          <Controller
            control={control}
            name='startTime'
            rules={{
              required: 'Start Time is required',
            }}
            render={({ fieldState: { error }, field }) => (
              <div>
                <div className='flex items-center relative'>
                  <TimePicker
                    disableClock
                    format='hh:mm a'
                    name={field.name}
                    onChange={(e) => {
                      const timeArr = e?.toString().split(':');
                      if (!timeArr) return;
                      const hours = parseInt(timeArr[0] || '0');
                      const minutes = parseInt(timeArr[1] || '0');
                      const date = dayjs(startDate)
                        .set('hour', hours)
                        .set('minute', minutes)
                        .toDate();
                      field.onChange(date);
                    }}
                    value={field.value}
                    disabled={disableDateTimeFields || !startDate}
                  />
                  <div className={TimeZoneClasses}> {tzCode}</div>
                </div>
                <ErrorComponent id='startTime-error' msg={error?.message} />
              </div>
            )}
          />
        </div>
        <div className='sm:w-2/5 xl:w-1/3 relative'>
          <label className='block text-sm font-medium leading-6 text-gray-900'>
            Local End Time
          </label>
          <Controller
            control={control}
            name='endTime'
            rules={{ required: 'End Time is required' }}
            render={({ fieldState: { error }, field }) => (
              <div>
                <div className='flex items-center relative'>
                  <TimePicker
                    disableClock
                    format='hh:mm a'
                    name={field.name}
                    onChange={(e) => {
                      const timeArr = e?.toString().split(':');
                      if (!timeArr) return;
                      const hours = parseInt(timeArr[0] || '0');
                      const minutes = parseInt(timeArr[1] || '0');
                      const date = dayjs(endDate)
                        .set('hour', hours)
                        .set('minute', minutes)
                        .toDate();
                      field.onChange(date);
                    }}
                    value={field.value}
                    disabled={disableDateTimeFields || !endDate}
                  />
                  <div className={TimeZoneClasses}> {tzCode}</div>
                </div>
                <ErrorComponent id='startTime-error' msg={error?.message} />
              </div>
            )}
          />
        </div>
        <div className='sm:w-2/5 xl:w-1/3 relative'>
          <label className='block text-sm font-medium leading-6 text-gray-900'>
            Local Setup Time
          </label>
          <Controller
            control={control}
            name='setupTime'
            rules={{ required: 'Setup Time is required' }}
            render={({ field, fieldState: { error } }) => (
              <div>
                <div className='flex items-center relative'>
                  <TimePicker
                    disableClock
                    format='hh:mm a'
                    name={field.name}
                    onChange={(e) => {
                      const timeArr = e?.toString().split(':');
                      if (!timeArr) return;
                      const hours = parseInt(timeArr[0] || '0');
                      const minutes = parseInt(timeArr[1] || '0');
                      const date = dayjs(startDate)
                        .set('hour', hours)
                        .set('minute', minutes)
                        .toDate();
                      field.onChange(date);
                    }}
                    value={field.value}
                    disabled={disableDateTimeFields || !startDate}
                  />
                  <div className={TimeZoneClasses}> {tzCode}</div>
                </div>
                <ErrorComponent id='startTime-error' msg={error?.message} />
              </div>
            )}
          />
        </div>
      </div>
    </div>
  );
};

export default OrderDateTimeForm;
