/* eslint-disable max-lines */
import React, {
  forwardRef,
  Fragment,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from 'react';
import { UseFormGetValues } from 'react-hook-form';
import classNames from 'classnames';
import SavedCardsList from 'components/CreditCard/SavedCardsList';
import { PAYMENT_METHOD_OPTIONS } from 'constants/common';
import {
  AV_RENTAL_EVENT_INFO_FORM_KEYS,
  DATE_TIME_FORM_KEYS,
  VENUE_INFO_FORM_KEYS,
  VENUE_STATE_NAME_KEY,
} from 'constants/order-form';
import { API_ROUTES } from 'constants/routes';
import { DEFAULT_TIMEZONE } from 'constants/timezone';
import { useRequest } from 'hooks/useRequest';
import * as _ from 'lodash';
import { useRecoilValue } from 'recoil';
import { currentOrderTaxRate, currentUserState } from 'state/atoms';
import { AVCustomer, UserCreditCard } from 'types/av-customer';
import { AVOrderFormField } from 'types/av-form-fields';
import {
  AVOrder,
  CreateAVOrder,
  EndClient,
  State,
  UpdateSetupIntentPayload,
} from 'types/av-orders';
import { AvailableBundles, AvailableItems } from 'types/item-bundle';
import { formVenueValues } from 'utils/avUtils';
import { AV_ORDER_STATUS } from 'utils/common.enum';
import { getFormatedDate, getFormatedTime } from 'utils/dayjs';
import { breakAndCapitalize, formatCurrency } from 'utils/helper';

import {
  CardNumberElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';
import { SetupIntent, SetupIntentResult } from '@stripe/stripe-js';

import CreditCardComponent from '../CreditCard';
import { EventInfoContainer, MainHeading, SubHeading } from '../UtilComponents';

interface IThirdStep {
  getValues: UseFormGetValues<CreateAVOrder>;
  items: AvailableItems[];
  bundles: AvailableBundles[];
  states: State[];
  endClients: EndClient[];
  goToFirstStep: () => void;
  goToSecondStep: (skipRushFeeCheck: boolean) => Promise<void>;
  setupIntentId: string | undefined;
  editOrder?: AVOrder | undefined;
  eventProfileDynamicFields: AVOrderFormField[];
  contactDynamicFields: AVOrderFormField[];
  eventDetailFormFields: AVOrderFormField[];
  programManagers: AVCustomer[];
  setupIntentClientSecret: string;
}

export interface IThirdStepRef {
  confirmSetupIntent: () => Promise<SetupIntentResult | null>;
}

const ThirdStep = forwardRef<IThirdStepRef, IThirdStep>(
  (
    {
      endClients,
      getValues,
      items,
      bundles,
      states,
      goToFirstStep,
      goToSecondStep,
      setupIntentId,
      editOrder,
      programManagers,
      contactDynamicFields,
      eventDetailFormFields,
      eventProfileDynamicFields,
      setupIntentClientSecret,
    },
    ref,
  ) => {
    const stripe = useStripe();
    const elements = useElements();
    const [hasValidCreditCardDetails, setHasValidCreditCardDetails] =
      useState<boolean>(false);

    const { post, get } = useRequest();
    const user = useRecoilValue(currentUserState);
    const orderTaxRate = useRecoilValue(currentOrderTaxRate);
    const [formValues, setFormValues] = useState<CreateAVOrder>();
    const [selectedBundles, setSelectedBundles] =
      useState<AvailableBundles[]>();
    const [selectedItems, setSelectedItems] = useState<AvailableItems[]>();
    const [userCreditCards, setUserCreditCards] = useState<UserCreditCard[]>();
    const [selectedCard, setSelectedCard] = useState<UserCreditCard | null>(
      null,
    );

    const getUserSavedCards = useCallback(async () => {
      const { data } = await get<UserCreditCard[]>(
        `${API_ROUTES.AV_CUSTOMER}${API_ROUTES.USER_CREDIT_CARDS}`,
      );
      const cardsList = data ?? [];
      cardsList.push({
        _id: 'new',
        brand: '',
        last4: '',
        paymentMethodId: '',
        expMonth: '',
        expYear: '',
      });
      setUserCreditCards(cardsList);
    }, [get]);

    useEffect(() => {
      getUserSavedCards();
    }, [getUserSavedCards]);

    const handleUpdateSetupIntent = useCallback(async () => {
      if (!setupIntentId || !formValues?.orderId) {
        return;
      }
      const res = await post<SetupIntent, UpdateSetupIntentPayload>(
        API_ROUTES.UPDATE_SETUP_INTENT,
        {
          setupIntentId,
          metadata: { orderId: formValues?.orderId },
        },
      );
      const { data } = res;

      if (!data) {
        return;
      }
    }, [post, setupIntentId, formValues?.orderId]);

    useEffect(() => {
      if (!editOrder) {
        handleUpdateSetupIntent();
      }
    }, [handleUpdateSetupIntent, editOrder]);

    const selectedEndClient = useMemo(
      () => endClients.find((e) => e._id === _.get(formValues, 'endClient')),
      [formValues, endClients],
    );

    const programManager = useMemo(() => {
      const manager = programManagers?.find(
        ({ _id }) => _id === _.get(formValues, 'programManager'),
      );

      if (!manager) return '';
      return `${manager.firstName} ${manager.lastName}`;
    }, [programManagers, formValues]);

    const isCreditCard = useMemo(
      () =>
        selectedEndClient?.paymentMethod === PAYMENT_METHOD_OPTIONS.CREDIT_CARD,
      [selectedEndClient],
    );

    useEffect(() => {
      if (formValues?.lineItems?.items.length) {
        const temp: AvailableItems[] = [];
        items.map((item) => {
          formValues?.lineItems?.items.map((selectedItem) => {
            const alreadyExists = temp.find((i) => i?._id === item?._id);
            if (
              selectedItem &&
              selectedItem.item === item?._id &&
              !alreadyExists
            ) {
              temp.push({
                ...item,
                quantity: selectedItem.quantity,
                price: selectedItem.price,
              });
            }
          });
        });
        setSelectedItems(temp);
      }
    }, [formValues?.lineItems?.items, items]);

    useEffect(() => {
      if (formValues?.lineItems?.bundles.length) {
        const temp: AvailableBundles[] = [];
        bundles.map((bundle) => {
          formValues?.lineItems?.bundles.map((selectedBundle) => {
            const alreadyExists = temp.find((b) => b?._id === bundle?._id);
            if (
              selectedBundle &&
              selectedBundle.bundle === bundle?._id &&
              !alreadyExists
            ) {
              temp.push({
                ...bundle,
                quantity: selectedBundle.quantity,
                price: selectedBundle.price,
              });
            }
          });
        });
        setSelectedBundles(temp);
      }
    }, [bundles, formValues?.lineItems?.bundles]);

    useEffect(() => {
      setFormValues(getValues());
    }, [getValues]);

    const getFormBasicValues = useCallback(
      (key: string) => {
        if (key === 'enteredBy') return `${user?.firstName} ${user?.lastName}`;
        if (key === 'endClient') {
          return selectedEndClient?.name;
        }

        if (key === 'programManager') {
          return programManager;
        }
        return _.get(formValues, key);
      },

      [formValues, programManager, selectedEndClient, user],
    );

    const getFormVenueValues = useCallback(
      (key: string) => {
        return formVenueValues(key, states, formValues);
      },

      [formValues, states],
    );

    const subTotal = useMemo(() => {
      const bundleValue = selectedBundles?.reduce(
        (acc, bundle) => acc + bundle.price * bundle.quantity,
        0,
      );

      const itemValue = selectedItems?.reduce((acc, item) => {
        if (!item.item) return acc;
        return acc + item.price * item.quantity;
      }, 0);

      let subTotal = 0;
      if (bundleValue) {
        subTotal += bundleValue;
      }
      if (itemValue) {
        subTotal += itemValue;
      }

      return subTotal;
    }, [selectedBundles, selectedItems]);

    const cartValue = useMemo(() => {
      let total = subTotal;

      if (editOrder?.discount) {
        total -= editOrder?.discount;
      }
      return total;
    }, [subTotal, editOrder?.discount]);

    const taxAmount = useMemo(() => {
      const taxRate = orderTaxRate / 100;
      return Number((cartValue * taxRate).toFixed(2));
    }, [orderTaxRate, cartValue]);

    const secondaryItemClasses = classNames(
      'text-base font-normal text-gray-850 mb-1',
    );

    const getDateTimeValues = (key: string, date: Date, timezone: string) => {
      if (key === 'startDate' || key === 'endDate') {
        return getFormatedDate(date);
      }
      return getFormatedTime(date, timezone);
    };

    const getDynamicFieldValue = useCallback(
      (field: AVOrderFormField) => {
        const { metadataKey, inputType } = field;
        const value =
          formValues?.metadata?.[metadataKey as keyof CreateAVOrder];
        if (inputType === 'date') {
          return getFormatedDate(value);
        }
        return value;
      },
      [formValues],
    );

    const country = useMemo(
      () => states.find((s) => s._id === formValues?.venue?.state)?.country,
      [formValues?.venue?.state, states],
    );

    const confirmSetupIntent = useCallback(async () => {
      if (!stripe || !setupIntentId) {
        return null;
      }
      if (selectedCard && selectedCard._id !== 'new') {
        const response = await stripe.confirmCardSetup(
          setupIntentClientSecret,
          {
            // eslint-disable-next-line camelcase
            payment_method: selectedCard.paymentMethodId,
          },
        );
        return response;
      }

      if (!elements) {
        return null;
      }
      const cardElement = elements.getElement(CardNumberElement);
      if (!cardElement || !hasValidCreditCardDetails) {
        return null;
      }
      const response = await stripe.confirmCardSetup(setupIntentClientSecret, {
        // eslint-disable-next-line camelcase
        payment_method: {
          card: cardElement,
        },
      });
      return response;
    }, [
      elements,
      hasValidCreditCardDetails,
      setupIntentId,
      stripe,
      setupIntentClientSecret,
      selectedCard,
    ]);

    useImperativeHandle(ref, () => ({
      confirmSetupIntent,
    }));

    return (
      <>
        <div className='flex-[0.6] h-full flex gap-4 flex-col'>
          <MainHeading title='Summary' />
          <div className='h-[calc(50%-25px)] border border-gray-300 rounded-[14px] p-3 overflow-y-auto'>
            <SubHeading title='Event Information' onClick={goToFirstStep} />
            <div className='mb-4'>
              {AV_RENTAL_EVENT_INFO_FORM_KEYS.map((key) => (
                <EventInfoContainer
                  key={key}
                  heading={breakAndCapitalize(key)}
                  value={getFormBasicValues(key)}
                />
              ))}
              {eventProfileDynamicFields.map((field) => {
                const { _id, label } = field;
                return (
                  <EventInfoContainer
                    key={_id}
                    heading={breakAndCapitalize(label)}
                    value={getDynamicFieldValue(field)}
                  />
                );
              })}
            </div>
            <div className='mb-4'>
              <SubHeading title='Venue Information' />
              {VENUE_INFO_FORM_KEYS.map((key) => {
                if (key === VENUE_STATE_NAME_KEY) {
                  return (
                    <Fragment key={key}>
                      <EventInfoContainer
                        heading='State'
                        value={getFormVenueValues(key)}
                      />
                      <EventInfoContainer heading='Country' value={country} />
                    </Fragment>
                  );
                }
                return (
                  <EventInfoContainer
                    key={key}
                    heading={breakAndCapitalize(key)}
                    value={getFormVenueValues(key)}
                  />
                );
              })}
            </div>
            <div className='mb-4'>
              <SubHeading title='Date & Time' />
              <EventInfoContainer
                heading={breakAndCapitalize('Local Timezone')}
                value={_.get(formValues, 'localTimeZone')}
              />
              {DATE_TIME_FORM_KEYS.map((key) => (
                <EventInfoContainer
                  key={key}
                  heading={breakAndCapitalize(key)}
                  value={getDateTimeValues(
                    key,
                    _.get(formValues, key),
                    _.get(formValues, 'localTimeZone') || DEFAULT_TIMEZONE,
                  )}
                />
              ))}
            </div>

            <div className='mb-4'>
              <SubHeading title='Contacts' />
              <p className='text-gray-600 text-sm font-medium mb-1'>
                Venue Contact
              </p>
              <EventInfoContainer
                heading={'Name'}
                value={_.get(formValues, 'venue.venueContact.name')}
              />
              <EventInfoContainer
                heading={'Phone Number'}
                value={_.get(formValues, 'venue.venueContact.phone')}
              />

              <p className='text-gray-600 text-sm font-medium mt-3 mb-1'>
                On-Site Contact
              </p>
              <EventInfoContainer
                heading={'Name'}
                value={_.get(formValues, 'onSiteContact.name')}
              />
              <EventInfoContainer
                heading={'Phone Number'}
                value={_.get(formValues, 'onSiteContact.phone')}
              />

              {contactDynamicFields.map((field) => {
                const { _id, label } = field;
                return (
                  <EventInfoContainer
                    key={_id}
                    heading={breakAndCapitalize(label)}
                    value={getDynamicFieldValue(field)}
                  />
                );
              })}
            </div>

            {eventDetailFormFields.length ? (
              <>
                <p className='text-gray-600 text-sm font-medium mt-3 mb-1'>
                  Event Details
                </p>

                {eventDetailFormFields.map((field) => {
                  const { _id, label } = field;
                  return (
                    <EventInfoContainer
                      key={_id}
                      heading={breakAndCapitalize(label)}
                      value={getDynamicFieldValue(field)}
                    />
                  );
                })}
              </>
            ) : null}

            <div className='mb-4'>
              <EventInfoContainer
                heading={'Comments'}
                value={_.get(formValues, 'comments')}
              />
            </div>
          </div>
          <div className='h-[calc(50%-25px)] border border-gray-300 rounded-[14px] p-3 overflow-y-auto'>
            <SubHeading
              title='Product Selection'
              onClick={() => goToSecondStep(false)}
            />
            {selectedBundles?.map((bundle, idx) => (
              <div key={`${bundle.bundle?._id}-${idx}`} className='mb-3'>
                <div className='flex items-center gap-x-3'>
                  <div className='font-medium text-sm text-gray-950'>{`${bundle.quantity} ${bundle.bundle?.name}`}</div>
                  <div className='text-sm font-normal text-gray-850'>
                    {formatCurrency(bundle.price * bundle.quantity || 0)}
                  </div>
                </div>

                <ul className='text-sm ml-5 font-normal text-gray-500'>
                  {bundle?.bundle?.items.map((bundleItem) => (
                    <li className='list-disc' key={bundleItem._id}>
                      {bundleItem.name}
                    </li>
                  ))}
                </ul>
              </div>
            ))}
            {selectedItems?.map((item, idx) => {
              if (!item.item) return null;
              return (
                <div key={`${item.item?._id}-${idx}`} className='mb-1'>
                  <div className='flex items-center gap-x-3'>
                    <div className='font-medium text-sm text-gray-950'>{`${item.quantity} ${item.item?.name}`}</div>
                    <div className='text-sm font-normal text-gray-850'>
                      {formatCurrency(item.price * item.quantity || 0)}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        <div
          className={classNames(
            'flex-[0.4] bg-stepperBg rounded-2xl ml-2 p-6 overflow-y-auto flex flex-col gap-4',
            {
              'h-fit': !isCreditCard,
            },
          )}
        >
          <MainHeading title='Payment Details' />

          <div>
            {editOrder?.discount ? (
              <div className='flex align-middle justify-between'>
                <div className={secondaryItemClasses}>{'Discount'}</div>
                <div className={secondaryItemClasses}>
                  {formatCurrency(editOrder?.discount ?? 0)}
                </div>
              </div>
            ) : null}
            <div className='flex align-middle justify-between'>
              <h6 className='mb-2 text-base font-medium  text-gray-950'>
                Sub-total
              </h6>
              <div className='text-base font-medium my-1 text-gray-950'>
                {formatCurrency(subTotal)}
              </div>
            </div>
            <div className='flex align-middle justify-between'>
              <div
                className={secondaryItemClasses}
              >{`Tax (${orderTaxRate.toFixed(2)}%)`}</div>
              <div className={secondaryItemClasses}>
                {formatCurrency(taxAmount)}
              </div>
            </div>
            {formValues?.rushFee !== 0 ? (
              <div className='flex align-middle justify-between'>
                <div className={secondaryItemClasses}>{'Rush Fee'}</div>
                <div className={secondaryItemClasses}>
                  {formatCurrency(formValues?.rushFee || 0)}
                </div>
              </div>
            ) : null}
            <div className='flex align-middle justify-between'>
              <h6 className='my-2 text-base font-bold text-gray-950'>
                ORDER TOTAL
              </h6>
              <div className='my-1 text-base font-bold text-gray-700'>
                {formatCurrency(
                  taxAmount + cartValue + (formValues?.rushFee || 0),
                )}
              </div>
            </div>
          </div>
          {(!editOrder || editOrder.orderStatus === AV_ORDER_STATUS.ON_HOLD) &&
          isCreditCard ? (
            <div>
              {userCreditCards && userCreditCards.length > 1 && (
                <SavedCardsList
                  cards={userCreditCards}
                  onSelect={setSelectedCard}
                  selectedCard={selectedCard}
                />
              )}
              {userCreditCards?.length === 1 || selectedCard?._id === 'new' ? (
                <CreditCardComponent
                  onCompleted={() => setHasValidCreditCardDetails(true)}
                />
              ) : null}
            </div>
          ) : null}
        </div>
      </>
    );
  },
);

ThirdStep.displayName = 'ThirdStep';

export default ThirdStep;
